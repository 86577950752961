import React from 'react';
import { Link } from "gatsby"
import { Container, Row, Col } from 'reactstrap';
import Layout from "../components/layout";
import SEO from "../components/seo";
import TopBunerProduct from '../components/top-buner/top-baner-product';


const PoliticaConfidecialnosty = (props) => {
   const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Политика конфиденциальности', url:'#', status:true}]
  return (
    <div>
        <SEO title="Политика конфиденциальности"
            description="">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Политика конфиденциальности'}
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={''}
                      breadCrumbs={breadCramb}>
            </TopBunerProduct>
            <Container fluid>
        <Row className="mt-5">
              <Col md="12" xs='12' className="text-center">
              <div class="block_title">
                    <h1 className="text-center text-md-left">Политика конфиденциальности</h1>
                </div>
        
             <h4 className="text-justify">Какая информация подлежит сбору</h4>
            <p>Сбору подлежат сведения, обеспечивающие возможность поддержки обратной связи с пользователем. (Имя, телефон, e-mail).</p>
            <p>Мы собираем также данные неличного характера, к которым относится информация о активности пользователя на Сайте. Так, мы регистрируем страницы и ссылки, посещенные пользователем и другие данные об использовании нашего сайтом.</p>
            <p>Также мы получаем и сохраняем обычную информацию, которую передает нам браузер пользователя. Это адрес IP, название браузера, язык, количество времени, которое проводимое на наших страницах, и их адреса. Для того чтобы наш сайт функционировал в оптимальном режиме, нам необходимо постоянно проводить анализ, для которого и нужны такие данные.</p>
            <p>Для веб-аналитики нужны сведения, которые передают Cookie -данные. Вы можете ознакомиться с политикой конфиденциальности популярных служб веб-аналитики:</p>
            <p> </p>
            <ul>
            <li>Google Analytics <Link to="http://www.google.com/policies/privacy/" target="_blank">(Политика конфиденциальности)</Link></li>
            <li>Яндекс.Метрика <Link to="http://legal.yandex.ru/confidential/" target="_blank">(Политика конфиденциальности)</Link></li>
            </ul>
            <p> </p>
            <p>Cookie (куки) — это файл маленького объема, в котором содержится только одна строка символов. Компьютер пользователя получает этот файл, когда он посещает определенный сайт. Когда пользователь зайдет на него в следующий раз, этот файл позволит узнать устройство и браузер пользователя.</p>
            <p>Пользователь может запретить браузеру получать такие файлы или настроить его так, чтобы он спрашивал разрешения при их отправке. Ряд сайтов, их услуг или функций не могут выполняться корректно без этих файлов. Помните, что данные, которые получены благодаря cookies, не могут идентифицировать конкретного пользователя. В них не передаются вирусы для заражения вашего устройства, они не способны запускать сторонние программы.</p>
            <p> </p>
            <h4>Как используется полученная информация</h4>
            <p> </p>
            <p>Сведения личного характера, предоставленные пользователем, используются для связи с ним, в том числе для направления коммерческих предложений.</p>
            <p> </p>
            <p>Наш сайт также использует cookies, в частности для сбора сведений неличного характера и контроля взаимодействия пользователей с Сайтом. Благодаря таким файлам мы регистрируем предпочтения и настройки пользователя. Мы получаем от пользователя через эти файлы только неличную информацию, которая нужна нам для статистического анализа и улучшения работы Сайта.</p>
            <p>В целях предотвращения случайного удаления или повреждения данных информация хранится в резервных копиях в течение 7 дней и может быть восстановлена по запросу пользователя.</p>
            <p> </p>
            <h4>Предоставление данных третьим лицам</h4>
            <p> </p>
            <p>Мы не продаем, не обмениваем и не передаем личные данные сторонним компаниям.</p>
            <p>Личные данные пользователей могут быть переданы лицам, не связанным с настоящим сайтом, если это необходимо:</p>
            <p> </p>
            <ul>
            <li>для соблюдения закона, нормативно-правового акта, исполнения решения суда;</li>
            <li>для выявления или воспрепятствования мошенничеству;</li>
            <li>для устранения технических неисправностей в работе сайта;</li>
            <li>для предоставления информации на основании запроса уполномоченных государственных органов.</li>
            </ul>
            <p> </p>
            <h4>Безопасность данных</h4>
            <p> </p>
            <p>Администрация сайта принимает все меры для защиты данных пользователей от несанкционированного доступа, в частности:</p>
            <ul>
            <li>регулярное обновление служб и систем управления сайтом и его содержимым;</li>
            <li>хранение архивных копий ресурса в защищенном облачном хранилище;</li>
            <li>регулярные проверки на предмет наличия вредоносных кодов;</li>
            <li>использование для размещения сайта виртуального выделенного сервера.</li>
            </ul>
            <p>В случае продажи настоящего сайта пользователи должны быть уведомлены об этом не позднее чем за 10 дней до совершения сделки</p>
            <p> </p>
            <h4>Изменения</h4>
            <p> </p>
            <p>Обновления политики конфиденциальности публикуются на данной странице. Для удобства пользователей все версии политики конфиденциальности подлежат сохранению в архивных файлах.</p>
            <p> </p>
            <h4>Согласие</h4>
            <p> </p>
            <p>Пользуясь услугами нашего сайта, пользователь автоматически соглашается с нашей политикой конфиденциальности.</p>
              </Col>
            </Row>
        </Container>
      </Layout>
      
    </div>
  );
};

export default PoliticaConfidecialnosty;